<template>
  <section id="class-settings" class="route-content flex wrap">
    <branded-divider />

    <!-- Existing Titles -->
    <div id="titles-list" role="menu">
      <h2 class="h4">Class <span class="accent--text">Titles</span></h2>

      <!-- Empty view (no titles) -->
      <div class="column wide--centered" v-if="titles.length === 0">
        <hr class="divider divider--tiny" />
        <p><em>No titles added</em></p>
      </div>

      <fieldset v-else>
        <legend>
          <i class="fas fa-filter accent--text"></i><span>Filter List</span>
        </legend>

        <strong class="h6">
          Search for a
          <span class="accent--text">Title</span>
        </strong>

        <label for="classTitle" class="flex">
          <input
            class="grow"
            v-model="searchInput"
            type="text"
            placeholder="Start typing to filter list"
          />

          <button
            class="button--outline clear-filter no-shrink"
            @click.stop="searchInput = null"
          >
            <i class="fas fa-times-square error--text"></i>
          </button>
        </label>

        <hr class="divider divider--tiny" />
      </fieldset>

      <!-- Titles (list items) -->
      <class-title-list-item
        v-for="title in filteredTitles"
        :data="title"
        :key="title.id"
        @toggle-title-active="toggleTitleActive(!title.active, title.id)"
        @update-title="t => updateTitleText(t, title.id)"
      />
    </div>

    <!-- Online Class Title form -->
    <form id="titles-form" @submit.prevent="createTitles">
      <fieldset>
        <legend>
          <i class="fal fa-heading accent--text" />
          <span>&nbsp;Create Title</span>
        </legend>

        <hr class="divider divider--tiny" />

        <label for="classTitles" class="wide">
          <strong class="h6">
            Enter
            <em class="accent--text">at least</em>
            one new title:
          </strong>
          <textarea
            class="wide"
            name="classTitles"
            :placeholder="textAreaPlaceholder"
            rows="10"
            v-model="classTitles"
          ></textarea>
        </label>
      </fieldset>

      <input
        @submit.prevent="createTitles"
        :disabled="!classTitles"
        class="wide"
        type="submit"
        value="Save Title(s)"
      />
    </form>
  </section>
</template>

<script>
import PermissionsMixin from "./mixins/permissions.mixin";
import {
  createOnlineClassTitle,
  getOnlineClassTitles,
  updateOnlineClassTitles
} from "../models/classes";
import BrandedDivider from "./BrandedDivider.vue";
import ClassTitleListItem from "./ClassTitleListItem.vue";
import SubrouteManagerMixin from "./mixins/subroute-manager.mixin";

export default {
  name: "ClassSettings",

  components: { BrandedDivider, ClassTitleListItem },

  mixins: [PermissionsMixin, SubrouteManagerMixin],

  data: () => ({ classTitles: null, searchInput: null, titles: [] }),

  computed: {
    filteredTitles() {
      if (!this.searchInput) return [...this.titles];
      const sortByTitle = (a, b) => a.title - b.title;
      const fuzzy = new RegExp(`\\${this.searchInput}`);
      return this.titles
        .filter(({ title }) => fuzzy.test(title))
        .sort(sortByTitle);
    },

    subroutes() {
      return [
        {
          name: "ListClasses",
          icon: "fas fa-presentation grey-light--text",
          condition: true
        },
        {
          name: "Classes",
          icon: "fas fa-calendar-check grey-light--text",
          condition: true
        },
        {
          name: "CreateClass",
          icon: "fas fa-plus-circle success--text",
          condition: true
        },
        {
          name: "ClassSettings",
          icon: "fas fa-cog grey-light--text",
          condition: this.isSuperAdminUser
        }
      ];
    },

    textAreaPlaceholder() {
      return `Separate multiple titles by a line-break e.g.:\n\nExample Yoga Class 1\nExample Yoga Class 2 (PST)\n( ... )`;
    }
  },

  async mounted() {
    this.broadcastSubroutes();
    this.titles = await getOnlineClassTitles();
  },

  methods: {
    async createTitles() {
      if (!this.classTitles) return;

      const active = true;
      const titlePromises = this.classTitles
        .split("\n")
        .filter(Boolean)
        .map(title => createOnlineClassTitle({ title, active }));

      const titles = await Promise.all(titlePromises);
      this.titles.push(...titles);
      this.classTitles = null;
      return titles;
    },

    getTitleFromList(titleId) {
      const titleIndex = this.titles.findIndex(({ id }) => id === titleId);
      return { titleIndex, title: this.titles[titleIndex] };
    },

    async toggleTitleActive(active, classTitleId) {
      const { title } = this.getTitleFromList(classTitleId);
      await this.updateTitle({ ...title, active });
    },

    async updateTitle(data) {
      this.loading = true;
      const { active, title, id: classTitleId } = data;
      await updateOnlineClassTitles({ classTitleId, active, title });
      const { titleIndex } = this.getTitleFromList(classTitleId);
      // Update item at index, then entire array reference
      this.titles[titleIndex] = data;
      this.titles = [...this.titles];
      this.loading = false;
    },

    async updateTitleText(text, titleId) {
      const { title } = this.getTitleFromList(titleId);
      title.title = text;
      await this.updateTitle(title);
    }
  }
};
</script>

<style lang="scss">
#class-settings {
  align-items: flex-start;

  h2,
  label > * {
    width: $parent;
  }
}

#titles-form {
  margin-left: $md;
  margin-right: $md;
  max-width: 24rem;
  width: $parent;
}

#titles-list {
  align-self: stretch;
  flex-grow: 1;

  .button--outline.clear-filter {
    border: none;
    box-shadow: none;
    width: 3rem;

    &:hover {
      background-color: $error;

      i {
        color: #fff;
      }
    }
  }

  .list-item {
    padding-right: $sm;
  }
}
</style>
