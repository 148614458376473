<template>
  <article
    class="list-item list-item--class-title"
    :class="{ 'list-item--active': editing }"
  >
    <input
      @blur="updateTitle"
      class="grow"
      placeholder="Enter new Online Class title"
      ref="input"
      type="text"
      v-if="editing"
      v-model="updatedTitle"
    />

    <span
      v-if="!editing"
      role="button"
      @click.stop="toggleEdit"
      class="label-text"
    >
      {{ data.title }}
    </span>

    <i
      @click.stop="$emit('toggle-title-active')"
      :class="{
        'fas fa-check-circle success--text': data.active,
        'fas fa-times-circle error--text': !data.active
      }"
    />
  </article>
</template>

<script>
export default {
  name: "ClassTitleListItem",

  props: { data: { type: Object, required: true } },

  data: () => ({ editing: false, updatedTitle: null }),

  methods: {
    focusInput() {
      if (!this.$refs.input) return;
      this.$refs.input.focus({ preventScroll: false });
      this.$refs.input.select();
    },

    onKeyEvent({ key }) {
      if (key === "Enter") {
        window.removeEventListener("keydown", this.onKeyEvent, false);
        return this.updateTitle();
      }
    },

    toggleEdit() {
      this.editing = !this.editing;

      if (this.editing) {
        this.updatedTitle = this.data.title;
        window.addEventListener("keydown", this.onKeyEvent, false);
        setTimeout(this.focusInput);
      }
    },

    updateTitle() {
      const title = this.updatedTitle;
      this.editing = false;

      if (title) {
        this.updatedTitle = null;
        this.$emit("update-title", title);
      }
    }
  }
};
</script>

.<style lang="scss">
.list-item--class-title {
  cursor: pointer;

  &.list-item--active {
    outline: 1px solid $link;
    z-index: 999;
  }

  .label-text {
    @include h6;
    flex-grow: 1;
    line-height: $xlg;
  }
}
</style>
